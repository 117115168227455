import request from '@/apis/axios'
import { IResponse } from '@/apis/axios/config'

import type {
  FindIdParam,
  FindIdResponse,
  FindPwParam,
  FindPwResponse,
  LoginParam,
  LoginResponse,
} from './types'

/**
 * 로그인 - http://3.38.149.75/api/login/loginUserInfo?UM_ID=dybtest&UM_PASSWD=1234
 */
export const loginApi = (
  params: LoginParam
): Promise<IResponse<LoginResponse>> => {
  return request.get({ url: '/api/login/loginUserInfo', params })
}

export const loginTokenInfoApi = (): Promise<IResponse<LoginResponse>> => {
  return request.get({ url: '/api/login/loginTokenInfo' })
}

/**
 * ID 찾기 - http://3.38.149.75/api/login/loginIdInfo?UM_NM=홍길동&P_TEL=01072318702
 */
export const findIdApi = (
  params: FindIdParam
): Promise<IResponse<FindIdResponse>> => {
  console.log(params)
  return request.get({ url: '/api/login/loginIdInfo', params })
}

/**
 * PW 찾기 - http://3.38.149.75/api/login/loginPwInfo?UM_ID=dybtest&P_TEL=01072318702
 */
export const findPwApi = (
  params: FindPwParam
): Promise<IResponse<FindPwResponse>> => {
  return request.get({ url: '/api/login/loginPwInfo', params })
}
